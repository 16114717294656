import React, { Fragment } from 'react'

import Helmet from 'react-helmet'

import styled from 'styled-components'

import root from 'utils/windowOrGlobal'

import config from 'config'

import Icon from 'components/Icon'

const Logo = styled(Icon).attrs({ name: 'sc-logo', id: 'logo' })`
  color: #fff;
  height: 50px;
`

const page = {
  title: 'Adventure Machine - Fly to Each Other - Mobile App',
  description:
    'Get cheap flight deal alerts from your selected airport to your preferred destination with the Adventure Machine mobile app. Download now.'
}

const ga = (...args) => root && root.ga && root.ga(...args)

const DownloadAMAppPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>{page.title}</title>
        <meta name='description' content={page.description} />
        <meta
          name='keywords'
          content='Adventure Machine,Adventure,StayCircles,Mobile download,App download,Cheap flights deals,Cheap flights,Travel,Stay with friends,Friends,Stays'
        />
        <meta
          name='image'
          content='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1542077900/assets/meta/Logo_White.png'
        />
        <meta itemProp='name' content={page.title} />
        <meta itemProp='description' content={page.description} />
        <meta
          itemProp='image'
          content='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1542077900/assets/meta/Logo_White.png'
        />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:title' content={page.title} />
        <meta name='twitter:description' content={page.description} />
        <meta name='og:title' content={page.title} />
        <meta name='og:description' content={page.description} />
        <meta
          name='og:image'
          content='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1534352384/assets/am_app_download_landing_-_metadata_2.0.jpg'
        />
        <meta
          name='og:url'
          content='https://www.staycircles.com/adventuremachineapp'
        />
        <meta name='og:site_name' content='StayCircles' />
        <meta name='og:type' content='website' />

        {config.ENV !== 'development' && (
          <script src='https://cdn.logrocket.com/LogRocket.min.js' />
        )}
        {config.ENV !== 'development' && (
          <script
            type='text/javascript'
            dangerouslySetInnerHTML={{
              __html: `
              window.LogRocket && window.LogRocket.init('staycircles/download-app${
          config.ENV === 'staging' ? '-staging' : ''
          }');
            `
            }}
          />
        )}

        <link
          href='//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/css/bootstrap.min.css'
          rel='stylesheet'
          id='bootstrap-css'
        />

        <style type='text/css'>{`
          @import url("https://fonts.googleapis.com/css?family=Lato:400,600");

          html {
            overflow-y: auto;
          }

          body {
            height: 100%;
            width: 100%;
            position: relative;
            font-family: 'Lato', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
            color: white;
          }

          .item h1,
          .item h3 {
            font-weight: 600;
          }

          #logo {
            position: absolute;
            top: 20px;
            left: 20px;
            height: 50px;
          }

          #signin {
            position: absolute;
            top: 20px;
            right: 20px;
            color: white;
            font-size: 18px;
          }

          #background-carousel {
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-color: #070707;
          }

          .carousel,
          .carousel-inner {
            width: 100%;
            height: 100%;
            z-index: 0;
            overflow: hidden;
          }

          .item {
            width: 100%;
            height: 100%;
            z-index: 0;
            background-position: center center;
            background-size: cover;
          }

          .text {
            display: flex;
            padding: 50px;
            width: 50vw;
            margin-left: 50vw;
            height: 100%;
            background: linear-gradient(270deg,rgba(0, 0, 0, 0.35) 0%,rgba(0, 0, 0, 0.25) 95%,rgba(0, 0, 0, 0) 100%);
            flex-direction: column;
            justify-content: center;
          }

          .item h1 {
            font-size: 48px;
            text-align: center;
            margin-top: 10px;
          }

          .am-logo {
            height: 48px;
          }

          .item h3 {
            position: relative;
            font-fize: 32px;
          }

          .airplane-icon {
            height: 30px;
            position: absolute;
            left: -35px;
            top: -3px;
          }

          .features {
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 20px;
          }

          .item img.hand {
            position: absolute;
            height: 80vh;
            right: 50vw;
            bottom: 0;
            margin-bottom: 0;
          }

          .store-logos {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
          }

          .item .logo {
            cursor: pointer;
            width: 200px;
            margin: 0 10px 20px;
          }

          @media (max-width: 400px) {
            .item h1 {
              font-size: 28px;
            }
            .am-logo {
              height: 28px;
              margin-left: auto;
            }
            .item h3 {
              margin: 2px 0;
              font-size: 18px;
            }
            .airplane-icon {
              height: 18px;
            }
            .features {
              max-width: 85%;
              margin-bottom: 20px;
            }
            .store-logos {
              flex-direction: row;
              align-items: center;
            }
            .item .logo {
              width: 120px;
            }
          }

          @media (max-width: 568px) {
            .text {
              top: 0;
              width: 100%;
              margin-left: 0;
              padding: 0 25px;
              height: 75vh;
              background: linear-gradient(180deg,rgba(0, 0, 0, 0.35) 0%,rgba(0, 0, 0, 0.25) 90%,rgba(0, 0, 0, 0) 100%);
            }
            .item img.hand {
              height: 29vh;
              right: 35vw;
            }
          }

          @media (min-width: 768px) and (max-width: 1025px) {
            .item img.hand {
              right: 45vw;
              height: 75vh;
            }

            .store-logos {
              flex-direction: column;
              align-items: center;
            }
          }


          /* Bootstrap Carousel fade */
          .carousel-fade .carousel-inner .item {
            opacity: 0;
            transition-property: opacity;
          }

          .carousel-fade .carousel-inner .active {
            opacity: 1;
          }

          .carousel-fade .carousel-inner .active.left,
          .carousel-fade .carousel-inner .active.right {
            left: 0;
            opacity: 0;
            z-index: 1;
          }

          .carousel-fade .carousel-inner .next.left,
          .carousel-fade .carousel-inner .prev.right {
            opacity: 1;
          }

          .carousel-fade .carousel-control {
            z-index: 2;
          }

          @media all and (transform-3d), (-webkit-transform-3d) {
            .carousel-fade .carousel-inner > .item.next,
            .carousel-fade .carousel-inner > .item.active.right {
              opacity: 0;
              -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
            }
            .carousel-fade .carousel-inner > .item.prev,
            .carousel-fade .carousel-inner > .item.active.left {
              opacity: 0;
              -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
            }
            .carousel-fade .carousel-inner > .item.next.left,
            .carousel-fade .carousel-inner > .item.prev.right,
            .carousel-fade .carousel-inner > .item.active {
              opacity: 1;
              -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
            }
          }

          @media screen and (orientation: landscape) and (max-width: 824px) and (max-height: 415px), screen and (max-width: 600px) {
            .item h1 {
              font-size: 28px;
            }
            .am-logo {
              height: 28px;
            }
            .item h3 {
              font-size: 18px;
              margin: 2px 0;
            }
            .airplane-icon {
              height: 18px;
              left: -20px;
              top: 0;
            }
            .item .logo {
              width: 120px;
            }
            #logo {
              top: 5px;
              left: 5px;
              height: 30px;
            }
            #signin {
              top: 5px;
              right: 8px;
              font-size: 16px;
            }
          }

          @media screen and (orientation: landscape) and (max-width: 824px) and (max-height: 415px) {
            .text {
              width: 65vw;
              margin-left: 35vw;
              padding: 10px;
            }
            .item img.hand {
              right: 65vw;
            }
          }
        `}</style>
      </Helmet>

      <a href='//staycircles.com' target='_blank'>
        <Logo />
      </a>

      <a href='//www.staycircles.com?login=true' target='_blank'>
        <span id='signin'>Log in</span>
      </a>

      <div id='background-carousel'>
        <div
          className='myCarousel carousel slide carousel-fade'
          data-ride='carousel-1'
        >
          <div className='carousel-inner'>
            <div
              className='item active'
              style={{
                backgroundImage:
                  'url(https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto:good/v1531128154/assets/applanding/am_deals_bg_1.jpg)'
              }}
            >
              <img
                className='hand'
                src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto:good/v1532360429/assets/applanding/am_hand_2.png'
              />
              <div className='text'>
                <img
                  src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto:good,c_scale,w_300/v1537259414/assets/branding/am_logo_white_thick.png'
                  className='center-block am-logo'
                />
                <h1>Adventure Machine</h1>
                <div className='features'>
                  <h3>
                    <img
                      src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1537276956/assets/applanding/am_airplane.png'
                      className='airplane-icon'
                    />
                    best cheap flight alerts
                  </h3>
                  <h3>
                    <img
                      src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1537276956/assets/applanding/am_airplane.png'
                      className='airplane-icon'
                    />
                    fly to dream destinations
                  </h3>
                  <h3>
                    <img
                      src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1537276956/assets/applanding/am_airplane.png'
                      className='airplane-icon'
                    />
                    free and easy-to-use app
                  </h3>
                  <h3>
                    <img
                      src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1537276956/assets/applanding/am_airplane.png'
                      className='airplane-icon'
                    />
                    never miss a deal!
                  </h3>
                  <h3>
                    <img
                      src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1537276956/assets/applanding/am_airplane.png'
                      className='airplane-icon'
                    />
                    see and stay with friends for free!
                  </h3>
                </div>
                <h3 className='text-center'>Select your app store:</h3>
                <div className='store-logos'>
                  <a
                    target='_blank'
                    href='https://itunes.apple.com/us/app/adventure-machine/id1412862317?ls=1&mt=8'
                    onClick={ga(
                      'send',
                      'event',
                      'AM App Store Link',
                      'open',
                      'iOS App Store'
                    )}
                  >
                    <img
                      src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_scale,w_300/v1537257132/assets/applanding/ios_app_store_white.png'
                      className='logo'
                    />
                  </a>
                  <a
                    target='_blank'
                    href='https://play.google.com/store/apps/details?id=com.staycircles.am.android'
                    onClick={ga(
                      'send',
                      'event',
                      'AM App Store Link',
                      'open',
                      'Android Play Store'
                    )}
                  >
                    <img
                      src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_scale,w_300/v1537257132/assets/applanding/android_play_store_white.png'
                      className='logo'
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <script
        type='text/javascript'
        src='//code.jquery.com/jquery-1.11.1.min.js'
      />
      <script
        type='text/javascript'
        src='//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/js/bootstrap.min.js'
      />
      {renderCarouselStarter()}

      {renderFacebookPixel()}
      {renderBranchTracker()}
    </Fragment>
  )
}

function renderCarouselStarter () {
  return (
    <script
      type='text/javascript'
      dangerouslySetInnerHTML={{
        __html: `
          $('.myCarousel').carousel({
            pause: 'none',
            interval: 10000
          });

          $(function () {
            document.addEventListener("touchmove", function(e){ e.preventDefault(); }, false);
          });
        `
      }}
    />
  )
}

function renderFacebookPixel () {
  if (config.ENV === 'production') {
    return (
      <Fragment>
        <script
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '858165974377524');
              fbq('track', 'PageView');
            `
          }}
        />
        <noscript>
          <img
            height='1'
            width='1'
            style={{ display: 'none' }}
            src='https://www.facebook.com/tr?id=858165974377524&ev=PageView&noscript=1'
          />
        </noscript>
      </Fragment>
    )
  }
}

function renderBranchTracker () {
  if (config.ENV === 'development') return null

  let BRANCH_KEY = 'key_live_ljw4XWLa6CQStQezNrrR3jfbAzna07G7'

  if (config.ENV === 'staging') {
    BRANCH_KEY = 'key_live_aku325OiXFPQc6iLz8ip1fenArmp67D5'
  }

  return (
    <script
      type='text/javascript'
      dangerouslySetInnerHTML={{
        __html: `
          <!-- Branch init code -->
          <script type="text/javascript">
            (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking getBrowserFingerprintId".split(" "), 0);

            branch.init(${BRANCH_KEY});
          </script>
          <!-- End Branch init code -->
        `
      }}
    />
  )
}

export default DownloadAMAppPage
